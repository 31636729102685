import React, { FC } from 'react'
import { FooterSeoNavigationItem } from './types/FooterSeoNavigationItem'

type Props = {
    link: FooterSeoNavigationItem
}

const FooterSeoNavigationLink: FC<Props> = ({ link }) => (
    <a
        href={link.targetUrl}
        target={link.target}
        rel={link.rel}
        className="block my-12 text-black hover:underline leading-[20px]"
    >
        {link.title}
    </a>
)

export default FooterSeoNavigationLink
