import React, { FC, memo } from 'react'
import Image from '@sport1/news-styleguide/Image'
import { Trademark } from './types/Trademark'

type Props = {
    trademark: Trademark
}

const FooterTrademark: FC<Props> = ({ trademark }) => {
    const image = (
        <Image
            size="S"
            key={trademark.iconUrl}
            imageUrl={trademark.iconUrl}
            alt={trademark.title}
            width={trademark.width}
            aspectRatio={trademark.width / trademark.height}
            resizeMode="contain"
        />
    )

    return (
        <li style={{ listStyle: 'none' }}>
            {trademark.targetUrl ? (
                <a href={trademark.targetUrl} target={trademark.target} rel={trademark.rel}>
                    {image}
                </a>
            ) : (
                image
            )}
        </li>
    )
}

export default memo(FooterTrademark)
