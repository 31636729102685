import React, { FC } from 'react'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'

const FooterLogo: FC = () => (
    <div className="bg-white">
        <div className="container">
            <Sport1Logo href="/" type="OnLight" width={112} testID="footer-banner-sport1-logo" />
        </div>
    </div>
)

export default FooterLogo
