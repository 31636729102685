import React, { FC } from 'react'
import { LayoutProps } from '@sport1/types/web'
import Config from '@/utils/Config'
import { isCrawlerRegExp } from '@/utils/userAgent'

const SourcePoint: FC<{ layoutData?: LayoutProps; cmpIsActive: boolean }> = ({
    layoutData,
    cmpIsActive,
}) => {
    const cmpConfig = {
        config: {
            accountId: Config.SOURCE_POINT_ACCOUNT_ID,
            baseEndpoint: Config.SOURCE_POINT_BASE_ENDPOINT,
            campaignEnv: Config.SOURCE_POINT_CAMPAIGN_ENV,
            propertyHref: Config.SOURCE_POINT_PROPERTY_HREF,
            propertyId: Config.SOURCE_POINT_PROPERTY_ID,
            managerId: Config.SOURCE_POINT_PRIVACY_MANAGER_ID,
        },
    }

    const meta = layoutData?.meta as { consentNeeded?: boolean } | undefined

    if (!cmpConfig.config.propertyId) {
        return null
    }

    const loadingScript =
        !cmpIsActive || meta?.consentNeeded === false ? null : (
            <script
                async={true}
                id="SourcePointCMPScript"
                dangerouslySetInnerHTML={{
                    __html: `!${isCrawlerRegExp}.test(navigator.userAgent) && (window.Cypress == undefined) && document.write('<scr' + 'ipt async importance="high" src="${cmpConfig.config.baseEndpoint}/wrapperMessagingWithoutDetection.js"></scr' + 'ipt>')`,
                }}
            />
        )
    return (
        <>
            <script
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || []; window.dataLayer.push({event: "cmp_disclosure_only"});`,
                }}
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `!function () { var e = function () { var e, t = "__tcfapiLocator", a = [], n = window; for (; n;) { try { if (n.frames[t]) { e = n; break } } catch (e) { } if (n === window.top) break; n = n.parent } e || (!function e() { var a = n.document, r = !!n.frames[t]; if (!r) if (a.body) { var i = a.createElement("iframe"); i.style.cssText = "display:none", i.name = t, a.body.appendChild(i) } else setTimeout(e, 5); return !r }(), n.__tcfapi = function () { for (var e, t = arguments.length, n = new Array(t), r = 0; r < t; r++)n[r] = arguments[r]; if (!n.length) return a; if ("setGdprApplies" === n[0]) n.length > 3 && 2 === parseInt(n[1], 10) && "boolean" == typeof n[3] && (e = n[3], "function" == typeof n[2] && n[2]("set", !0)); else if ("ping" === n[0]) { var i = { gdprApplies: e, cmpLoaded: !1, cmpStatus: "stub" }; "function" == typeof n[2] && n[2](i) } else a.push(n) }, n.addEventListener("message", (function (e) { var t = "string" == typeof e.data, a = {}; try { a = t ? JSON.parse(e.data) : e.data } catch (e) { } var n = a.__tcfapiCall; n && window.__tcfapi && window.__tcfapi(n.command, n.version, (function (a, r) { var i = { __tcfapiReturn: { returnValue: a, success: r, callId: n.callId } }; t && (i = JSON.stringify(i)), e.source.postMessage(i, "*") }), n.parameter) }), !1)) }; "undefined" != typeof module ? module.exports = e : e() }();`,
                }}
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `window._sp_ = ${JSON.stringify(cmpConfig)}`,
                }}
            />
            {loadingScript}
        </>
    )
}

export const openPrivacyManager = (): void => {
    if (typeof window !== 'undefined' && window._sp_?.config?.managerId) {
        if (window._sp_.loadPrivacyManagerModal) {
            /**
             * if loadPrivacyManagerModal is present, open right away
             */
            window._sp_.loadPrivacyManagerModal(window._sp_.config.managerId)
        } else if (window.SourcePointCMPScript) {
            /**
             * the script has been added, but loadPrivacyManagerModal
             * has yet to be provided (is not present right away on
             * script load)
             */
            setTimeout(openPrivacyManager, 100)
        } else {
            /**
             * load script and retry function
             */
            const script = document.createElement('script')
            script.id = 'SourcePointCMPScript'
            script.src = `${window._sp_.config.baseEndpoint}/wrapperMessagingWithoutDetection.js`
            script.addEventListener('load', openPrivacyManager)
            document.head.append(script)
        }
    }
}

export default SourcePoint
