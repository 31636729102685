import React, { FC } from 'react'
import { FooterNavigationItem } from './types/FooterNavigationItem'
import { openPrivacyManager } from '@/components/ThirdParty/SourcePoint'

type Props = {
    link: FooterNavigationItem
}

const FooterNavigationLink: FC<Props> = ({ link }) => {
    if (link.targetUrl === '') return null

    if (link.targetUrl?.includes('sport1://privacy')) {
        return (
            <li className="mb-12 text-base leading-tight text-black pr-6 list-none">
                <a className="hover:underline cursor-pointer" onClick={() => openPrivacyManager()}>
                    {link.title}
                </a>
            </li>
        )
    }

    return (
        <li className="mb-12 text-base leading-tight text-black pr-6 list-none">
            <a
                href={link.targetUrl}
                target={link.target}
                rel={link.rel}
                className="hover:underline"
            >
                {link.title}
            </a>
        </li>
    )
}

export default FooterNavigationLink
