import React, { FC } from 'react'

const year = new Date().getFullYear()

const FooterCopyright: FC = () => (
    <div className="container">
        <p className="text-12 leading-none text-black md:text-left">
            Copyright © {year} Sport1 GmbH. Alle Rechte vorbehalten.
        </p>
    </div>
)

export default FooterCopyright
