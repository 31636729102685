import React, { FC, Fragment } from 'react'
import Font from '@sport1/news-styleguide/Font'
import FooterSeoNavigationLink from './FooterSeoNavigationLink'
import { navigationItems } from './FooterDataItems'
import { sport1CondensedBlackItalic } from '@/helpers/fonts'

const FooterSeoNavigation: FC = () => (
    <section className="mx-auto hidden flex-row md:flex w-full">
        {navigationItems.map((column, index) => (
            <div key={`FooterSeoNavigationRow${index}`} className="container">
                {Object.entries(column).map(([key, value]) => (
                    <Fragment key={key}>
                        <Font
                            fontVariant="Title-M"
                            fontFamilyVariant="Sport1-CondensedBlackItalic"
                            nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                        >
                            {key}
                        </Font>
                        {value.map(listElemnt => (
                            <FooterSeoNavigationLink
                                link={listElemnt}
                                key={`${listElemnt.title}-${key}`}
                            />
                        ))}
                    </Fragment>
                ))}
            </div>
        ))}
    </section>
)

export default FooterSeoNavigation
