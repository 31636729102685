import React, { FC, useMemo } from 'react'
import { FooterNavigationItem } from './types/FooterNavigationItem'
import FooterNavigationLink from './FooterNavigationLink'
import Config from '@/utils/Config'

type Props = {
    type?: 'TV'
}
const FooterNavigation: FC<Props> = ({ type }) => {
    const navigationItems: Array<FooterNavigationItem> = useMemo(
        () => [
            {
                title: 'Impressum',
                targetUrl: `${Config.HOST}/unternehmen/impressum`,
                target: '_self',
            },
            {
                title: 'Datenschutzhinweise',
                targetUrl: `${Config.HOST}/unternehmen/datenschutz`,
                target: '_self',
            },
            {
                title: 'Datenschutzeinstellungen',
                targetUrl: `sport1://privacy`,
                target: '_self',
            },
            {
                title: 'Nutzungsbedingungen',
                targetUrl:
                    type === 'TV'
                        ? `${Config.HOST}/news/unternehmen/nutzungsbedingungen-sport1plus`
                        : `${Config.HOST}/news/unternehmen/nutzungsbedingungen`,
                target: '_self',
            },
            {
                title: 'Nutzung mit Werbung beenden',
                targetUrl: `${Config.HOST}/consent/widerrufen`,
                target: '_self',
            },
        ],
        [type]
    )

    return (
        <div className="w-full">
            <ul className="flex flex-col sm:flex-row sm:flex-wrap pb-1 ps-0">
                {navigationItems.map((navigationItem, index) => (
                    <FooterNavigationLink
                        link={navigationItem}
                        key={`${navigationItem.title}-${index}`}
                    />
                ))}
            </ul>
        </div>
    )
}

export default FooterNavigation
